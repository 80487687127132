import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { ARGUS_APM } from "../../apm";
import Types from "prop-types";
import { ANALYTIKA_EVENTS } from "../../utils/constants";
import eventAnalytics from "../../analytikaV2";
import { withWidth } from "@material-ui/core";
import axios from "axios";
import {bugsnagInstance} from "../../bugsnag";
import Galileo from "../../galileo";

class MotInvitation extends React.Component {
  constructor(props) {
    super(props);
    eventAnalytics.setPageName(ANALYTIKA_EVENTS.PAGE.BUSINESS_INVITATION);
    eventAnalytics.publish(
      ANALYTIKA_EVENTS.IDNW_VIEW_PAGE.event_name,
      ANALYTIKA_EVENTS.IDNW_VIEW_PAGE.event_version,
      {
        page_name: ANALYTIKA_EVENTS.PAGE.BUSINESS_INVITATION,
      }
    );

    this.state = {
      error: null,
      name: "",
      phone_number: null,
      user_urn: null,
      token: this.props.match.params.token,
      submitting: true,
      userId: 0,
    };
    this.textInput = React.createRef();
    getUserinfo().then(({ data }) => {
      this.setState({name:data.given_name})
      this.setState({phone_number:data.phone_number})
      this.setState({user_urn:`urn:customer:${data.sub}`})
      this.setState({userId:data.sub})
      this.setState({submitting:false})
    });
  }

  async onCancel(event) {
    eventAnalytics.publish(
        ANALYTIKA_EVENTS.IDNW_TAP_BUTTON.event_name,
        ANALYTIKA_EVENTS.IDNW_TAP_BUTTON.event_version,
        {
          button_name: "not_now",
          invitation_code: this.state.token,
          user_id: this.state.userId,
        });
    event.preventDefault();
    await (eventAnalytics.flush());
    window.location.assign("/logout");
  }

  async onSubmit(event) {
    eventAnalytics.publish(
      ANALYTIKA_EVENTS.IDNW_TAP_BUTTON.event_name,
      ANALYTIKA_EVENTS.IDNW_TAP_BUTTON.event_version,
      {
        button_name: "accept_invitation",
        invitation_code: this.state.token,
        user_id: this.state.userId,
      });
    event.preventDefault();

    this.setState({
      submitting: true,
      error: null,
    });

    try {
      eventAnalytics.publish(
          ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_name,
          ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_version,
          {
            request_type: "invitation_code_marilyn_submit",
            user_id: this.state.userId,
          });
      await submitInvitationToMarilyn({
        code: this.state.token,
        user_urn: this.state.user_urn,
        phone: this.state.phone_number,
      });
      eventAnalytics.publish(
          ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_name,
          ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_version,
          {
            request_type: "invitation_code_marilyn_submit",
            request_status: "success",
            user_id: this.state.userId,
          });

      await this.tryToProcessInvitationAtMot();

      window.location.replace(`${
          process.env.REACT_APP_MOT_SATURN_HOST
      }/auth/login?force_sso=true`);
    } catch (e) {
      switch (e.message) {
        case 'MotGwError': this.handleMotAuthErrors(e.cause); break;
        default: this.handleMarilynErrors(e);
      }
    }

    this.setState({ submitting: false });
  }

  handleMarilynErrors(e) {
    const { t } = this.props;
    const { token } = this.state;
    eventAnalytics.publish(
        ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_name,
        ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_version,
        {
          request_type: "invitation_code_marilyn_submit",
          request_status: "fail",
          error_code_string: e.response?.data?.code,
          error_description: e.response?.data?.message,
          user_id: this.state.userId,
        });
    this.onRequestError(e);
    switch (e.response?.status) {
      case 401: this.setState({ error: { message: t("business_invitation:unauthorized") }}); break;
      case 404: this.setState({ error: { message: t("business_invitation:invitation_code_not_found", {token}) }}); break;
      case 400:
          switch (e.response.data.code) {
            case "BU-0010": this.setState({ error: { message: t("business_invitation:invitation_code_expired", {token}) }}); break;
            case "BU-0011": this.setState({ error: { message: t("business_invitation:invitation_code_already_used", {token}) }}); break;
            case "BU-0012": this.setState({ error: { message: t("business_invitation:invitation_code_inactivated", {token}) }}); break;
            case "BU-0013": this.setState({ error: { message: t("business_invitation:invitation_code_revoked", {token}) }}); break;
            case "BU-0014": this.setState({ error: { message: t("business_invitation:invitation_code_cancelled", {token}) }}); break;
            case "BU-0015": this.setState({ error: { message: t("business_invitation:invitation_code_rejected", {token}) }}); break;
            case "BU-0016": this.setState({ error: { message: t("business_invitation:invitation_code_bad_shape", {token}) }}); break;
            default: this.setState({ error: { message: t("business_invitation:invitation_code_invalid", {token}) }}); break;
          } break;
      default: this.setState({ error: { message: t("business_invitation:ERROR_UNKNOWN") }});
    }
  }

  async tryToProcessInvitationAtMot() {
    const { userId } = this.state;
    if (await Galileo.fetchIsMotInvitationS2S(userId)) {
      return;
    }
    try {
      eventAnalytics.publish(
          ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_name,
          ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_version,
          {
            request_type: "invitation_code_mot_auth_submit",
            user_id: this.state.userId,
          });
      await submitInvitationToMoT({
        code: this.state.token,
      });
      eventAnalytics.publish(
          ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_name,
          ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_version,
          {
            request_type: "invitation_code_mot_auth_submit",
            request_status: "success",
            user_id: this.state.userId,
          });
    } catch (e) {
      throw new Error("MotGwError", {cause: e});
    }
  }

  handleMotAuthErrors(e) {
    const { t } = this.props;

    eventAnalytics.publish(
        ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_name,
        ANALYTIKA_EVENTS.IDNW_SEND_WEBREQUEST.event_version,
        {
          request_type: "invitation_code_mot_auth_submit",
          request_status: "fail",
          error_code_string: e.response?.data?.code,
          error_description: e.response?.data?.message,
          user_id: this.state.userId,
        });
    this.onRequestError(e);
    switch (e.response?.status) {
      default: this.setState({ error: { message: t("business_invitation:mot_request_failed")  } });
    }
  }

  onRequestError(e) {
    bugsnagInstance.notify(e);
    ARGUS_APM.captureError(e);
  }

  render() {
    const { t, submitError } = this.props;
    const { error, submitting, name, phone_number } = this.state;

    return (
        <form id="formInvite" onSubmit={this.onSubmit.bind(this)} autoComplete="off">
          <div dir="ltr">
            <h1 className="heading-h1-express center express-common">
              {t("business_invitation:business_invitation_header")}
            </h1>
          </div>
          <h3 className="heading-h3-express center express-common">
            {t("business_invitation:greetings", {name, phone_number})}
          </h3>
          {!submitError && error && (
              <div className="invalid show">
                {error.message}
              </div>
          )}
          {submitError && <div className="invalid show">{submitError}</div>}
          <input
              className="btn-careem btn-careem-primary"
              type="submit"
              value={submitting ? t("common:please_wait") : t("business_invitation:accept_invitation")}
              disabled={submitting}
          />
          <input
              className="no-btn-careem"
              type="button"
              onClick={this.onCancel.bind(this)}

              value={t("business_invitation:not_now")}
              disabled={submitting}
          />
        </form>
    );

  }
}


export async function submitInvitationToMarilyn({ code, user_urn, phone }) {
  return axios.patch(
      "/api/business/v1/invites/process",
      {
        code,
        phone,
        user_urn,
        status: "accepted"
      }
  );
}
export async function submitInvitationToMoT({ code }) {
  return axios.post(
      `/api/mot_auth/invitation/${code}`
  );
}
export async function getUserinfo() {
  return axios.get("/api/profile/userinfo");
}

MotInvitation.propTypes = {
  clientId: Types.string,
  match: Types.object.isRequired,
  t: Types.func,
  submitError: Types.any,
  width: Types.string,
};

export default withWidth()(withRouter(withTranslation()(MotInvitation)));
export const MotInvitationUnwrapped = MotInvitation;
